.footer {
   background-color: #4e4e5f;
   color: whitesmoke;
   height: 27px;
   display: flex;
   align-items: center;
   padding: 0 20px;
 }
 
 .footer-content {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
 }
 
 .brand {
   margin: 0;
   font-size: small;
 }
 
 .copyright {
   font-size: small;
 }
 