/* Ensure the body and html take the full height */
html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  /* The layout should take up the full height of the viewport */
  .layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* The content should grow to fill the space between the header and footer */
  .content {
    flex: 1;
  }
  
  /* Footer styles */
  footer {
    background-color: #28353e;
    color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  