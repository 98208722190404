a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  color: #aaadab;
  border-bottom: 0px;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(234, 41, 70, 0.05);
}

.border-bottom {
  border-bottom: 0px;
}

.custom-toggler {
  background-color:whitesmoke !important;
}

.custom-navbar{
  color: white;
  background-color:#4e4e5f !important;
}
