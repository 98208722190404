/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

html, body {
  height: 100%;
  margin: 0;
  background-color:#ffffff; /* Slightly less dark background */
  color:#2c2c2c; /* White text */
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

.jumbotron{
  background-color:whitesmoke;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #ffffff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.custom-modal {
  background-color: #2c2c2c;
}


